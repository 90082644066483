<template>
  <van-list v-model="loading" :finished="finished" loading-text="努力加载中" @load="onLoad">
    <div v-for="groupedListItem in list" :key="groupedListItem.title">
      <div class="grouped-list-title">{{ groupedListItem.title }}</div>
      <div v-for="item in groupedListItem.items" :key="item.id" class="card" @click="onClick(item)">
        <div class="card-header">
          <span>{{ formatTime(item.reportTime) }}</span>

          <div @click.stop class="state">
            <van-popover v-model="item.show" placement="top" theme="dark" trigger="click">
              <div style="padding: 7px 10px; font-size: 13px">
                <span style="margin-right: 10px">{{ item.approvers.join("、") }}</span>
                <span>{{ ["待审核", "已通过", "已拒绝"][item.approvalResult] || "" }}</span>
              </div>
              <template #reference>
                <state-icon :type="['3', '1', '2'][item.approvalResult] || ''"/>
              </template>
            </van-popover>
          </div>

          <svg
              v-if="item.approvalResult === 1"
              class="icon"
              aria-hidden="true"
              @click.stop="copy(item.detail)"
          >
            <use xlink:href="#icon-copy"></use>
          </svg>
        </div>

        <div class="detail" @click.stop="onClick(item)">{{ item.detail }}</div>

        <div class="card-footer" @click="onClick(item)">
          <span>{{ item.stage === 2 ? "一阶段" : item.stage === 3 ? "二阶段" : "" }}</span>
          <div>
            <span>{{ item.creator }}</span>
            <span style="margin-left: 7px">{{ dayjs(item.updatedTime).format("YYYY-MM-DD HH:mm:ss") }}</span>
          </div>
        </div>
      </div>
    </div>
    <van-empty v-if="list.length === 0 && finished"/>
  </van-list>
</template>

<script>
import dayjs from "dayjs";
import StateIcon from "@/components/Apply/ApplyStateIcon.vue";
import {fanGroupReportGroup, fanGroupReportList} from "@/api/hoss";

export default {
  components: {StateIcon},
  data() {
    return {
      loading: false,
      finished: false,

      list: [],
      page: 0,
    };
  },
  created() {
    const {clientId} = this.$route.query;
    this.clientId = clientId;
  },
  methods: {
    dayjs,
    // 破冰记录列表
    async getFanGroupReportList() {
      const {clientId, page} = this;
      const {value} = await fanGroupReportGroup({clientId});
      this.list.push(...value);
      this.finished = true;
      this.loading = false;
    },
    onLoad() {
      this.page++;
      this.getFanGroupReportList();
    },
    formatTime(t) {
      return dayjs(t).format("YYYY-MM-DD");
    },
    onClick({id}) {
      this.$router.push(`/hoss/fanGroupReport?id=${id}&clientId=${this.clientId}`);
    },
    copy(value) {
      this.$copyText(value).then(
          () => {
            this.$toast("已复制");
          },
          () => {
            this.$toast("复制失败");
          }
      );
    },
  },
};
</script>

<style lang="less" scoped>
.card {
  font-size: 12px;
  background-color: var(--blueCardBackgroundColor);
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 10px;

  .card-header {
    display: flex;
    align-items: center;
    width: 100%;

    .state {
      // 位于父div的水平中间
      margin-left: 85px;
    }

    .icon {
      width: 15px;
      height: 15px;
      vertical-align: -0.15em;
      fill: currentColor;
      overflow: hidden;
      color: var(--mainColor);
    }
  }

  .detail {
    color: var(--subTitleColor);
    line-height: 1.3em;
    height: 3.9em;
    margin: 5px 0;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .card-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--mainColor);
  }
}

.loading-box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;

  span {
    margin-right: 10px;
  }
}

.van-empty {
  width: 180px;
  height: 180px;
  margin: 0 auto;
}

.grouped-list-title {
  font-size: 14px;
  color: var(--titleColor);
  margin-bottom: 10px;
  font-weight: bold;
}
</style>
